import { passwordChangeComplete, passwordChangeError } from './actions';
import { PASSWORD_CHANGE_START } from './action-types';
const {
  Authenticate
} = svs.retail;
const connector = store => {
  const onPasswordChangeSuccess = () => {
    store.dispatch(passwordChangeComplete());
  };
  const onPasswordChangeError = error => {
    store.dispatch(passwordChangeError(error));
  };
  const events = {
    [Authenticate.Events.PASSWORD_CHANGED]: onPasswordChangeSuccess,
    [Authenticate.Events.PASSWORD_CHANGE_ERROR]: onPasswordChangeError
  };
  Authenticate.on(events, this);
  return next => action => {
    if (action.type === PASSWORD_CHANGE_START) {
      Authenticate.changePassword({
        currentPassword: action.currentPassword,
        newPassword: action.newPassword,
        repeatNewPassword: action.repeatNewPassword
      });
    }
    return next(action);
  };
};
export default connector;