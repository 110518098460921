import { PASSWORD_CHANGE_CLEAR_ERROR, PASSWORD_CHANGE_START, PASSWORD_CHANGE_COMPLETE, PASSWORD_CHANGE_ERROR, PASSWORD_CHANGE_VALIDATION_ERROR, PASSWORD_CHANGE_CLEAR_VALIDATION_ERRORS } from './action-types';
const passwordChangeClearError = () => ({
  type: PASSWORD_CHANGE_CLEAR_ERROR
});
const passwordChangeStart = ({
  currentPassword,
  newPassword,
  repeatNewPassword
}) => ({
  type: PASSWORD_CHANGE_START,
  currentPassword,
  newPassword,
  repeatNewPassword
});
const passwordChangeComplete = () => ({
  type: PASSWORD_CHANGE_COMPLETE
});
const passwordChangeError = error => ({
  type: PASSWORD_CHANGE_ERROR,
  error
});
const passwordChangeClearValidationErrors = () => ({
  type: PASSWORD_CHANGE_VALIDATION_ERROR
});
const passwordChangeValidationError = ({
  field,
  message
}) => ({
  type: PASSWORD_CHANGE_CLEAR_VALIDATION_ERRORS,
  validationError: {
    [field]: message
  }
});
export { passwordChangeClearError, passwordChangeStart, passwordChangeComplete, passwordChangeError, passwordChangeClearValidationErrors, passwordChangeValidationError };