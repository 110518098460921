import { PASSWORD_CHANGE_CLEAR_ERROR, PASSWORD_CHANGE_START, PASSWORD_CHANGE_COMPLETE, PASSWORD_CHANGE_ERROR, PASSWORD_CHANGE_VALIDATION_ERROR, PASSWORD_CHANGE_CLEAR_VALIDATION_ERRORS } from './action-types';
const defaultState = {
  active: false,
  completed: false,
  error: null,
  validationError: null
};
function passwordChangeReducer(state = defaultState, action = {}) {
  switch (action.type) {
    case PASSWORD_CHANGE_CLEAR_ERROR:
      return {
        ...state,
        error: null
      };
    case PASSWORD_CHANGE_ERROR:
      return {
        ...state,
        active: false,
        error: action.error
      };
    case PASSWORD_CHANGE_START:
      return {
        ...state,
        active: true
      };
    case PASSWORD_CHANGE_COMPLETE:
      return {
        ...state,
        active: true,
        complete: true
      };
    case PASSWORD_CHANGE_VALIDATION_ERROR:
      return {
        ...state,
        active: false,
        validationErrors: action.validationError
      };
    case PASSWORD_CHANGE_CLEAR_VALIDATION_ERRORS:
      return {
        ...state,
        validationErrors: null
      };
    default:
      return state;
  }
}
export default passwordChangeReducer;