import { passwordChangeStart, passwordChangeClearError } from './store/actions';
import store from './store';
const {
  useState,
  useCallback
} = React;
const {
  useDispatch,
  useSelector
} = ReactRedux;
const {
  Loader
} = svs.components.ReactLoader;
const {
  ReactButton: Button
} = svs.ui;
const {
  TextInput
} = svs.ui.ReactFormInputs;
const {
  ReactForm: {
    Form
  }
} = svs.ui;
const {
  Dialog
} = svs.retailUi;
const {
  RedirectToReturnUrl
} = svs.retailUtils;
const PasswordChange = ({
  desc,
  title,
  onCancel
}) => {
  const dispatch = useDispatch();
  const active = useSelector(({
    passwordChange
  }) => passwordChange.active);
  const complete = useSelector(({
    passwordChange
  }) => passwordChange.complete);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [repeatNewPassword, setRepeatNewPassword] = useState('');
  const {
    error,
    validationErrors
  } = useSelector(({
    passwordChange
  }) => passwordChange);
  const handleCurrentPasswordChange = useCallback(e => {
    setCurrentPassword(e.target.value);
  }, []);
  const handleNewPasswordChange = useCallback(e => {
    setNewPassword(e.target.value);
  }, []);
  const handleRepeatNewPasswordChange = useCallback(e => {
    setRepeatNewPassword(e.target.value);
  }, []);
  const handleCloseErrorDialog = useCallback(() => dispatch(passwordChangeClearError()), [dispatch]);
  const handleSubmit = useCallback((e, {
    currentPassword,
    newPassword,
    repeatNewPassword
  }, isValid) => {
    e.preventDefault();
    if (isValid) {
      dispatch(passwordChangeStart({
        currentPassword: currentPassword.value,
        newPassword: newPassword.value,
        repeatNewPassword: repeatNewPassword.value
      }));
    }
  }, [dispatch]);
  if (complete) {
    return <RedirectToReturnUrl />;
  }
  return <div className="bg-white border-grey-300 padding-3 margin-bottom-2 ompo-main-content margin-center">
      {error && <Dialog buttonCallback={handleCloseErrorDialog} buttonTitle="Okej" icon={error.icon} message={error.message} severe={error.severe} title={error.title} type={error.type} />}
      <Form className="control-group margin-bottom-0" onSubmit={handleSubmit} setError={validationErrors}>
        <h1>
          {title}
        </h1>
        {desc && <p>
            {desc}
          </p>}
        <TextInput disabled={active} errorMessageRequired="Du måste anget ett lösenord" isRequired label="NUVARANDE LÖSENORD" name="currentPassword" onChange={handleCurrentPasswordChange} type="password" value={currentPassword} />
        <TextInput disabled={active} errorMessageRequired="Du måste anget ett lösenord" isRequired label="NYTT LÖSENORD" name="newPassword" onChange={handleNewPasswordChange} type="password" value={newPassword} />
        <TextInput disabled={active} errorMessageRequired="Du måste anget ett lösenord" isRequired label="UPPREPA NYTT LÖSENORD" name="repeatNewPassword" onChange={handleRepeatNewPasswordChange} type="password" value={repeatNewPassword} />
        <div className="grid-row margin-top-2">
          {onCancel && <div className="col-xs-6 padding-xs-right-1">
              <Button block onClick={onCancel} transparent>
                AVBRYT
              </Button>
            </div>}
          <div className="col-xs-6 padding-xs-left-1">
            <Button block focus type="submit">
              {active ? <Loader size={200} /> : 'BYT LÖSENORD'}
            </Button>
          </div>
        </div>
      </Form>
    </div>;
};
export default {
  store,
  PasswordChange
};